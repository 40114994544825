import CoverForDamageImage from '@resources/images/ev-charger-carousel-damage-cover.png';
import EvChargerExpertsImage from '@resources/images/ev-charger-carousel-experts.png';
import SustainabilityImage from '@resources/images/ev-charger-carousel-sustainability.png';
import TrustpilotDesktopImage from '@resources/images/EvChargerTrustPilotLarge.png';
import TrustpilotTabletImage from '@resources/images/EvChargerTrustPilotMedium.png';
import TrustpilotMobileImage from '@resources/images/EvChargerTrustPilotSmall.png';

export const heroHeading = 'EV Charger Cover.';
export const heroSubHeading = 'Insurance to help keep your EV on the road.';

export const eligibilityModalTitle = 'You’re eligible for EV Charger Cover if:';
export const eligibilityModalBulletPoints = [
  'you own the EV charger.',
  'your charger is not used to generate income.',
  'your charger does not have an output over 22 kW.',
  'you live in England, Wales, Scotland or the Isle of Wight.',
];

export const coverCardLinkText = 'See terms for cancellation fees';

export const howClaimsWorkSteps = [
  {
    title: 'Support 24/7',
    description: `Give us a call, tell us what's happened, and we'll guide you through quick fixes you can try at home. Or we'll book in a home visit from an engineer.`,
  },
  {
    title: 'An engineer checks the problem ',
    description:
      'We aim to have an engineer out to take a look at your charger as quick as we can.',
  },
  {
    title: 'We repair or replace your EV charger',
    description:
      'If the engineer can’t repair your charger, we’ll fit your replacement as soon as possible.',
  },
];

export const howClaimsWorkBackgroundPosition = {
  small: { bottom: '-20px' },
  medium: { height: '910px', bottom: '-60px' },
};

export const discoverMoreHeading = 'Discover more';
export const discoverMoreSubHeading = 'Unlock the full potential of your cover.';

export const discoverMoreCarouselCardData = [
  {
    imageSrc: CoverForDamageImage,
    title: 'Cover for damage and fire',
    description:
      'If accidents happen and your charger stops working or is unsafe, you’re protected with accidental damage cover. And you’re insured against fire damage to your charger, too. Terms apply.',
  },
  {
    imageSrc: EvChargerExpertsImage,
    title: 'We’re EV charger experts',
    description:
      'OVO fit them, power them and champion the shift to electric. So who better to cover your charger?',
  },
  {
    imageSrc: SustainabilityImage,
    title: 'Sustainability at the core',
    description:
      'By choosing to repair over replace, you can reduce waste. You’ll keep your charger working and avoid adding to landfill as much as possible.',
  },
];

export const trustpilotBackgroundImages = {
  mobileImage: TrustpilotMobileImage,
  tabletImage: TrustpilotTabletImage,
  desktopImage: TrustpilotDesktopImage,
};
