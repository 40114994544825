import styled from 'styled-components';
import { WindowLocation } from '@reach/router';
import { theme } from '@ovotech/element';

import {
  coverCardLinkText,
  discoverMoreCarouselCardData,
  discoverMoreHeading,
  discoverMoreSubHeading,
  eligibilityModalBulletPoints,
  eligibilityModalTitle,
  heroHeading,
  heroSubHeading,
  howClaimsWorkBackgroundPosition,
  howClaimsWorkSteps,
  trustpilotBackgroundImages,
} from '@src/modules/EvCharger/constants';
import { ModalContextProvider } from '@contexts/ModalContext';
import { BenefitsSection } from '@src/modules/EvCharger/BenefitsSection';
import { ChooseYourCoverSection } from '@components/ChooseYourCoverSection';
import { DiscoverMoreSection } from '@components/DiscoverMoreSection';
import { HeroSection } from '@components/Hero/HeroSection';
import { HowClaimsWorkSection } from '@components/HowClaimsWorkSection';
import { TrustSection } from '@components/TrustSection';
import { BasicPage } from '@components/layout';
import useEvCharger from '@hooks/useEvCharger';
import EvChargerHero from '@resources/images/EvChargerHero.webp';
import HowItWorksBackground from '@resources/images/electric-vehicle-mountain-road.png';
import 'swiper/css';
import 'swiper/css/effect-creative';
import { BrandCoreColor } from '@entities/types';

const Root = styled.div`
  background-color: ${({ theme }) => theme.core.color.brand.offwhite};
`;

interface Props {
  location?: WindowLocation;
}

const EvCharger: React.FC<Props> = ({ location }) => {
  const { quotes } = useEvCharger();

  const evChargerNoExcessQuote = quotes.find((quote) => quote.excess === 0);

  return (
    <Root>
      <ModalContextProvider>
        <BasicPage
          pageName="ev-charger"
          isNested={true}
          showTopPageBanner
          quote={evChargerNoExcessQuote}
          location={location}
        >
          <HeroSection
            heading={heroHeading}
            subHeading={heroSubHeading}
            image={EvChargerHero}
            quotes={quotes}
            eligibilityModalTitle={eligibilityModalTitle}
            eligibilityModalBulletPoints={eligibilityModalBulletPoints}
          />
          <BenefitsSection
            sectionBackgroundColour={theme.core.color.brand.offwhite as BrandCoreColor}
          />
          <ChooseYourCoverSection
            quotes={quotes}
            sectionBackgroundColour={theme.core.color.brand.ovo as BrandCoreColor}
            coverCardLinkText={coverCardLinkText}
          />
          <HowClaimsWorkSection
            howClaimsWorkSteps={howClaimsWorkSteps}
            sectionBackgroundColour={theme.core.color.brand.offwhite as BrandCoreColor}
            backgroundImage={HowItWorksBackground}
            howClaimsWorkBackgroundPosition={howClaimsWorkBackgroundPosition}
          />
          <DiscoverMoreSection
            heading={discoverMoreHeading}
            subHeading={discoverMoreSubHeading}
            carouselCardData={discoverMoreCarouselCardData}
            sectionBackgroundColour={theme.core.color.brand.ovo as BrandCoreColor}
          />
          <TrustSection backgroundImages={trustpilotBackgroundImages} />
        </BasicPage>
      </ModalContextProvider>
    </Root>
  );
};

export default EvCharger;
